<script>
import Drawer from "vue-simple-drawer";
import i18n from "../i18n";
import appConfig from "@/app.config";
import SimpleBar  from "simplebar-vue";
import { required} from "vuelidate/lib/validators";

import {
  authMethods,
  commonMethods,
  projectMethods
} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
       {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "Español",
        },
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      currentUser:{},
      openRightDrawer: false,
      news:[],
      version: appConfig.version,
      selectedApp: null,
      apps: JSON.parse(localStorage.getItem('auth.currentUser')).user.company.products,
      projects:[],
      project:null,
      new_project:{},
      showModal: false,
      submitted:false,
      app:'',
      showProjectSelection: false
    };
  },
  validations: {
		new_project: {
			name: {
				required,
			},
		}
	},
  components: {
    Drawer,
    SimpleBar
   },
   props: {
    logo: {
      type: String,
      default: '',
    },
    logo_small:{
      type: String,
      default: '',
    }
  },
  mounted() {
    this.currentUser = JSON.parse(localStorage.getItem("auth.currentUser"));

    this.loadProjects();
    /*if(localStorage.getItem("app")){
      this.selectApp(localStorage.getItem("app"));
      this.app = localStorage.getItem("app");
    } else if (this.apps.personalization) {
      localStorage.setItem("app", 'personal');
      this.selectedApp = require("@/assets/images/go-personal.svg");
      this.app = 'personal'
    }else if (this.apps.live_shopping) {
      localStorage.setItem("app", 'live');
      this.app = 'live'
      this.selectedApp = require("@/assets/images/go-live-shop.svg");
    }*/
    localStorage.setItem("app", 'personal');
    //this.selectedApp = require("@/assets/images/go-personal.svg")

    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
  },
  methods: {
    ...authMethods,
    ...commonMethods,
    ...projectMethods,

    loadProjects(){
      //let loader = this.$loading.show();
			this.getProjects().then((projects)=>{
				if(projects&& projects.data){
				this.projects= projects.data;

        if(localStorage.getItem("current_project")){
          this.project = this.projects.filter(p=>p._id == localStorage.getItem("current_project")).length > 0 ? this.projects.filter(p=>p._id == localStorage.getItem("current_project"))[0] : null;
        }else{
          if(this.projects.length> 0){
            //this.project = this.projects[0];
            //localStorage.setItem("current_project",this.projects[0]._id)
            this.showProjectSelection = true;
          }
        }
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("projects.get_projects_error"),title:  this.$t("projects.title") });
			}).finally(()=>{
				//loader.hide();
			});
    },
    rightDrawer() {
      this.openRightDrawer = !this.openRightDrawer;
    },

    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
      localStorage.setItem('user_locale',locale);
      location.reload();
    },
    logoutUser() {

      this.logOut().then(()=>{
        this.$router.push({
          path: "/login",
        }
      ).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      });
      })
    },
    toProfile(){
      this.$router.push({
          path: "/profile",
        }
      ).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      });
    },
    toCompany() {
      this.$router.push({
          path: "/company",
        }
      ).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      });
    },

    loadNews(){
      let loader = this.$loading.show();
      this.openRightDrawer=true
      this.getNews().then((news)=>{
        this.news = news.data;
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },
    toHelp(){
      window.open('https://academy.gopersonal.ai')
    },
    toSupport(){
      window.open('https://support.gopersonal.com')
    },
    toApiReference(){
      window.open('https://docs.goshops.com/api-refernce')
    },
    selectApp(app){
      switch(app) {
          case 'live':
            this.selectedApp = require("@/assets/images/go-live-shop.svg");
            break;
          case 'search':
            this.selectedApp = require("@/assets/images/go-search.svg");
            break;
          case 'reco':
            this.selectedApp = require("@/assets/images/go-reco.svg");
            break;
          case 'personal':
            this.selectedApp = require("@/assets/images/go-personal.svg");
            break;
        }
        //this.app = app;
        if(app!=localStorage.getItem("app")){
          localStorage.setItem("app", app);
          this.$parent.reloadMenu();
          //window.open("/","_self")
          let path='';
          if(app == 'personal'){
            path = '/personalizations'
          }else if(app== 'live'){
            path = '/metrics'
          }else if(app== 'reco'){
            path = '/reco-analyze'
          }else if(app== 'search'){
            path = '/search-analyze'
          }
          this.$router.push({
            path: path,
          })
          
        }
    },
    onSelectProject(project){
      this.showProjectSelection = false;
      localStorage.setItem("current_project",project._id);
      this.project= project;

      window.open("/","_self")
    },
    onNewProjectClicked(){
      this.showModal=true;
    },
    onCancelCreateClicked(){
      this.showModal = false;
      this.new_project ={};
		},
    onConfirmNewProjectClicked(){
      this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

      let loader = this.$loading.show();
			this.createProject(this.new_project).then(()=>{
				this.$notify({ type: 'success', text: this.$t('projects.create_project_success'),title: this.$t('projects.title') });
				this.showModal= false;

         this.loadProjects();
				
			}).catch((()=>{
        this.$notify({ type: 'error', text: this.$t('projects.create_project_error'),title: this.$t('projects.title') });
			})).finally(()=>{
				loader.hide();
				this.submitted = false;
			});
      
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo.svg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="17" />
            </span>
          </router-link>

          <router-link tag="a" to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/go_shops_small.svg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/go personal.svg" alt height="30" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu">
          <i class="fa fa-fw fa-bars"></i>
        </button>
        <div style="margin: auto; text-align: center; visibility: hidden !important;" v-if="currentUser && currentUser.user?.role == 'admin'">
          <div class="d-none d-lg-inline-block noti-icon">
            <img :src=selectedApp height="30px" class="me-2"/>
            <img v-if="app!='personal'" style="opacity: 0.3;" class="me-2 interact" src="https://go-shops.nyc3.digitaloceanspaces.com/uploads/2023/10/81dc16ff-6432-4b8e-ab07-055fbf1bacd6.svg" height="30px" v-on:click="selectApp('personal')"/>
            <img v-if="app!='live'" style="opacity: 0.3;" class="me-2 interact" src="https://go-live.fra1.digitaloceanspaces.com/uploads/2023/09/6924e456-be9e-455f-8ddf-3f66f1fa66c5.svg" height="30px" v-on:click="selectApp('live')"/>
            <img v-if="app!='reco'" style="opacity: 0.3;" class="me-2 interact" src="https://go-live.fra1.digitaloceanspaces.com/uploads/2023/09/ad889703-e61f-4cfb-a185-38266097d353.svg" height="30px" v-on:click="selectApp('reco')"/>
            <img v-if="app!='search'" style="opacity: 0.3;" class="me-2 interact" src="https://go-live.fra1.digitaloceanspaces.com/uploads/2023/09/0ecc96a7-3b5f-4b09-9d22-2d344301815e.svg" height="30px" v-on:click="selectApp('search')"/>
          </div>
          <!--<b-dropdown ref="appsDropdown" class="d-none d-lg-inline-block noti-icon" menu-class="dropdown-menu-lg dropdown-menu-end" right
          toggle-class="header-item" variant="black">
          <template v-slot:button-content>
            <img :src=selectedApp height="30px" class="me-2"/>
            <img style="opacity: 0.3;" class="me-2" src="https://go-live.fra1.digitaloceanspaces.com/uploads/2023/09/08e9aff6-8fb6-41ea-af43-21390cbe14d2.svg" height="30px"/>
            <img style="opacity: 0.3;" class="me-2" src="https://go-live.fra1.digitaloceanspaces.com/uploads/2023/09/6924e456-be9e-455f-8ddf-3f66f1fa66c5.svg" height="30px"/>
            <img style="opacity: 0.3;" class="me-2" src="https://go-live.fra1.digitaloceanspaces.com/uploads/2023/09/ad889703-e61f-4cfb-a185-38266097d353.svg" height="30px"/>
            <img style="opacity: 0.3;" class="me-2" src="https://go-live.fra1.digitaloceanspaces.com/uploads/2023/09/0ecc96a7-3b5f-4b09-9d22-2d344301815e.svg" height="30px"/>
          </template>

          <div class="px-lg-2">
            <div class="row no-gutters interact" v-if="apps.personalization">
              <div class="col">
                <a class="dropdown-icon-item" v-on:click="selectApp('personal')">
                  <img :src="require('@/assets/images/go-personal.svg')"/>
                  <span>{{$t("navbar.personalization")}}</span>
                </a>
              </div>
            </div>
            <div class="row ni-gutters interact" v-if="apps.live_shopping">
              <div class="col">
                <a class="dropdown-icon-item" v-on:click="selectApp('live')">
                  <img :src="require('@/assets/images/go-live-shop.svg')"/>
                  <span>{{$t("navbar.live_shopping")}}</span>
                </a>
              </div>
            </div>
            <div class="row no-gutters interact" v-if="apps.recommendation">
              <div class="col">
                <a class="dropdown-icon-item" v-on:click="selectApp('reco')">
                  <img :src="require('@/assets/images/go-reco.svg')"/>
                  <span>{{$t("navbar.recommendation")}}</span>
                </a>
              </div>
            </div>
            <div class="row no-gutters interact" v-if="apps.search">
              <div class="col">
                <a class="dropdown-icon-item" v-on:click="selectApp('search')">
                  <img :src="require('@/assets/images/go-search.svg')"/>
                  <span>{{$t("navbar.search")}}</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown>-->
        </div>
      </div>

      <div class="d-flex">
        <b-dropdown variant="black" toggle-class="header-item" menu-class="dropdown-menu-lg dropdown-menu-end" right>
          <template v-slot:button-content>
            <i class="bx bx-box"></i>
            {{project?.name}}
            <i class="mdi mdi-chevron-down"></i>
          </template>

          <div class="p-2">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{this.$t('projects.title')}}
                </h6>
              </div>
              <div class="small">
                {{this.$t('projects.subtitle')}}
              </div>
            </div>
          </div>
          <b-dropdown-divider></b-dropdown-divider>
          <SimpleBar style="max-height: 230px;">
            <div v-for="project in projects" :key="project._id" v-on:click="onSelectProject(project)">
              <b-dropdown-item class="d-block">
                {{ project?.name }}  
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
            </div>
          </SimpleBar>
          <div class="p-2 border-top d-grid" v-on:click="onNewProjectClicked">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-right-circle me-1"></i>
              {{this.$t('projects.new_project')}}
            </a>
          </div>
        </b-dropdown>
        <b-modal
          v-model="showModal"
          :title="$t('projects.new_project')"
          title-class="text-black font-18"
          body-class="p-3"
          hide-footer>
            <form @submit.prevent="onConfirmNewProjectClicked">
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="name">{{$t('projects.new_project_name')}}*</label>
                    <input
                      id="name"
                      v-model="new_project.name"
                      type="text"
                      class="form-control"
                      :placeholder="$t('projects.new_project_name_placeholder')"
                      :class="{'is-invalid':submitted && $v.new_project.name.$error}"
                    />
                      <div v-if="submitted && !$v.new_project.name.required" class="invalid-feedback">
                        {{$t('projects.new_project_name_required')}}
                      </div>
                  </div>
                </div>
              </div>
              <div class="text-end pt-5 mt-3">
                  <b-button variant="light" @click="onCancelCreateClicked">{{$t('common.cancel')}}</b-button>
                  <b-button type="submit" variant="primary" class="ms-1"
                  >{{$t('common.confirm')}}</b-button>
              </div>
            </form>
        </b-modal>
        <b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content>
            <img class :src="flag" alt="Header Language" height="16" />
            <!--{{ text }} -->
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
            :class="{ active: lan === entry.language }">
            <img
              :src="`${entry.flag}`"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block">
          <button type="button" class="btn header-item noti-icon p-0" @click="toSupport" v-b-popover.hover.bottom="$t('common.help_center')">
            <i class="bx bx-message-square-dots"></i>
          </button>
        </div>

        <div class="dropdown d-none d-lg-inline-block ms-2">
          <button type="button" class="btn header-item noti-icon p-0" @click="toHelp" v-b-popover.hover.bottom="$t('common.academy')">
            <i class="bx bx-book"></i>
          </button>
        </div>

        <b-dropdown right variant="black" toggle-class="header-item" menu-class="dropdown-menu-end">
          <template v-slot:button-content>
            <div class="header-image-text">
              <div class="avatar-xs header-profile-user header-profile-user-color" v-if="!currentUser.user || !currentUser.user.imageURL">
                  <span class="avatar-title rounded-circle header-profile-user">{{ currentUser.user != null? currentUser.user.name.charAt(0) : ""}}</span>
              </div>
              <img
                class="rounded-circle header-profile-user"
                :src="currentUser.user.imageURL"
                alt="Header Avatar"
                v-if="currentUser.user && currentUser.user.imageURL"
              />
              <span class="d-none d-xl-inline-block ms-1">{{
                currentUser.user != null? currentUser.user.name : ""
              }}</span>
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block ms-1"></i>
            </div>
          </template>
          <!-- item-->
          <b-dropdown-item @click="toProfile()">
            <i class="bx bx-user font-size-16 align-middle me-1"></i>
              {{ $t("navbar.profile") }}
          </b-dropdown-item>
          <b-dropdown-item @click="toCompany()" v-if="currentUser.user && currentUser.user.role =='admin'" >
            <i class="bx bx-building-house font-size-16 align-middle me-1"></i>
              {{ $t("company.title") }}
          </b-dropdown-item>
          <b-dropdown-item @click="loadNews" >
            <i class="bx bx-star font-size-16 align-middle me-1"></i>
            {{ $t("navbar.news") }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item>
            {{ $t('common.version') + version}}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-item @click="logoutUser()">
            <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ $t("navbar.logout") }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <Drawer
        @close="rightDrawer"
        :align="'right'"
        :closeable="true"
        :maskClosable="true"
        :zIndex="1002">
        <div v-if="openRightDrawer">
          <div class="offcanvas-header">
            <h4 class="offcanvas-title" id="offcanvasExampleLabel">
              <i class="bx bx-star font-size-16 align-middle me-1"></i>
              {{ $t("navbar.news") }}
            </h4>
          </div>
          <hr class="mt-1" />
          <div class="offcanvas-body">
            <div v-for="n in news" :key="n._id">
              <h6 class="text-bold mb-0">{{n.title}}</h6>
              <small>{{ new Date(n.date).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' }) }}</small>
              <div class="mt-2 mb-4" v-html="n.html">
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    <b-modal v-model="showProjectSelection" centered :no-close-on-backdrop="true" :title="$t('projects.title')" title-class="font-18" hide-footer :hide-header-close="true">
      <div class="d-inline-flex w-100">
        <i class="bx bx-box"></i>
          <p class="small ms-1">
            {{this.$t('projects.subtitle')}}
          </p>
      </div>
      
      <hr/>  
      <div class="interact" v-for="project in projects" :key="project._id" v-on:click="onSelectProject(project)">
        <div class="d-inline-flex w-100">
          <a class="dropdown-item"> {{project.name}} </a>
          <i class="bx bx-chevron-right font-size-20"></i>
        </div>
        <hr/>  
      </div>
          
    </b-modal>
  </header>
</template>
<style>
.header-image-text {
  display: inline-flex;
  align-items: center;
} 

.header-profile-user-color {
  background: #ffffff;
}

.vue-simple-drawer{
  max-width: 500px !important;
} 

.interact{
    cursor: pointer;
}

.dropdown-toggle {
    padding-top: 5px !important;
}
</style>
